
export const PROD = {
  apiKey: "AIzaSyAoSd8FR6vsalQxo11W9YJ2FONpj-xbRGs",
  // apiKey: "AIzaSyDGLGrDsxUK2MPKd4xI9klvQaNrgWWRkEE",
  authDomain: "bughouse-274816.firebaseapp.com",
  databaseURL: "https://bughouse-274816.firebaseio.com",
  projectId: "bughouse-274816",
  storageBucket: "bughouse-274816.appspot.com",
  messagingSenderId: "757856014866",
  appId: "1:757856014866:web:cd7a7ac96676ba9255fecb",
  measurementId: "G-BGZVQM1CG5",
};

export const DEV = {
  apiKey: "AIzaSyBkutQM-XveFZhNH5uk-hls8oh4zLsLgVw",
  // apiKey: "AIzaSyD_hg77lc_QHhsdATF1Uy7nZItToB-FGXw",
  authDomain: "bughouse-dev.firebaseapp.com",
  databaseURL: "https://bughouse-dev.firebaseio.com",
  projectId: "bughouse-dev",
  storageBucket: "bughouse-dev.appspot.com",
  messagingSenderId: "49685448221",
  appId: "1:49685448221:web:52bab4a65a9aea225a28f4",
};
